import { colorPalette, weightPalette } from "gx-npm-common-styles";
const surveyWorkspaceOverviewStyles = {
  slideInDrawer: {
    "& .swha-MuiPaper-root": {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      backgroundColor: colorPalette.basic.white.hex,
      background: `linear-gradient(to right, ${colorPalette.neutrals.tin.hex} 45%, transparent 45% )`,
    },
    "& .swha-MuiDrawer-paper": {
      height: "90%",
    },
  },
  closeButton: {
    position: "absolute" as const,
    right: "10px",
    top: "10px",
  },
  root: {
    height: "100%",
    justifyContent: "space-between",
    color: colorPalette.neutrals.carbon.hex,
    display: "flex",
    minWidth: "1024px",
    maxWidth: 1400,
    "@media (min-width:1400px)": {
      margin: "0 auto",
      width: 1400,
    },
    "& .btn-primary": {
      width: "auto",
    },
  },
  descriptionTab: {
    display: "flex",
    flexDirection: "column" as const,
    width: "45%",
    paddingTop: "64px",
    paddingLeft: "56px",
    paddingRight: "48px",
    backgroundColor: colorPalette.neutrals.tin.hex,
  },
  videoTab: {
    display: "flex",
    flexDirection: "column" as string as "column",
    width: "61%",
    marginTop: 76,
    paddingLeft: "56px",
    paddingRight: "56px",
  },
  loaderWrapper: { position: "absolute" as const },
  headerBox: {
    maxWidth: "492px",
  },
  orangeText: {
    color: colorPalette.brand.yam.hex,
  },
  bulletList: {
    paddingLeft: "16px",
    paddingRight: "16px",
    "& li": {
      display: "flex",
      paddingBottom: "16px",
      "&::before": {
        color: colorPalette.brand.yam.hex,
        content: '"\\2022"',
        display: "inline-block",
        fontWeight: weightPalette.bold.amount,
        marginLeft: "-1em",
        width: "1em",
        marginTop: "2px",
      },
      "& .gx-p": {
        color: colorPalette.neutrals.coal.hex,
      },
    },
  },
  btnWrapper: {
    display: "block",
  },
  listItemTitle: {
    marginTop: 32,
  },
};

export default surveyWorkspaceOverviewStyles;
