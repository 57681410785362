// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B0bxYZ61HiwrMBYunE3S{height:48px;top:0;position:fixed;background-color:var(--color-interactions-light-berry);left:0;right:0}.B0bxYZ61HiwrMBYunE3S .SnackbarBanner-UI-MuiSnackbar-root{margin-top:0;top:0;height:48px;right:0;left:calc(100% - 178px);width:178px}.MyzGKWh1XjAzhCQCVms1{float:right;margin:8px 16px !important}.Mzj7_L8Mh3HmkLqojDpc{margin-right:8px}.ZJLeDup0moMUfQYIArdN{padding:12px 16px;width:632px;float:left;color:var(--color-neutrals-coal) !important}.ZJLeDup0moMUfQYIArdN>svg{vertical-align:text-top;margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/ui/preview-banner/preview-banner.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,KAAA,CACA,cAAA,CACA,sDAAA,CACA,MAAA,CACA,OAAA,CAEA,0DACE,YAAA,CACA,KAAA,CACA,WAAA,CACA,OAAA,CACA,uBAAA,CACA,WAAA,CAIJ,sBACE,WAAA,CACA,0BAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,2CAAA,CAEA,0BACE,uBAAA,CACA,gBAAA","sourcesContent":[".previewHeader {\n  height: 48px;\n  top: 0;\n  position: fixed;\n  background-color: var(--color-interactions-light-berry);\n  left: 0;\n  right: 0;\n\n  :global(.SnackbarBanner-UI-MuiSnackbar-root) {\n    margin-top: 0;\n    top: 0;\n    height: 48px;\n    right: 0;\n    left: calc(100% - 178px);\n    width: 178px;\n  }\n}\n\n.sendEmailPreviewBtn {\n  float: right;\n  margin: 8px 16px !important;\n}\n\n.sendEmailIcon {\n  margin-right: 8px;\n}\n\n.previewHeaderContent {\n  padding: 12px 16px;\n  width: 632px;\n  float: left;\n  color: var(--color-neutrals-coal) !important;\n\n  & > svg {\n    vertical-align: text-top;\n    margin-right: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"previewHeader": `B0bxYZ61HiwrMBYunE3S`,
	"sendEmailPreviewBtn": `MyzGKWh1XjAzhCQCVms1`,
	"sendEmailIcon": `Mzj7_L8Mh3HmkLqojDpc`,
	"previewHeaderContent": `ZJLeDup0moMUfQYIArdN`
};
export default ___CSS_LOADER_EXPORT___;
