import { GartnerFooter, Paragraph } from "gx-npm-ui";
import classes from "./preview-error.module.scss";
import PreviewErrorImg from "../../assets/images/preview-error.svg";
import { useTranslation } from "react-i18next";

const PreviewError = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.errorContainer}>
        <img src={PreviewErrorImg} alt={t("Questionnaire Error")} />
        <Paragraph boldness={"semi"} type={"p1"} rootClassName={classes.paragraph}>
          {t("Please complete your questionnaire set up to access your preview.")}
        </Paragraph>
      </div>
      <div className={classes.footer}>
        <GartnerFooter />
      </div>
    </div>
  );
};

export default PreviewError;
