import { colorPalette, weightPalette } from "gx-npm-common-styles";

const tabMenuComponentStyles = {
  tabMenuRoot: {
    minWidth: "1024px",
    "& button.swha-MuiTab-root": {
      color: `var(${colorPalette.neutrals.iron.name})`,
      flexBasis: "initial",
      flexGrow: "initial",
      fontSize: "15px",
      fontVariationSettings: weightPalette.bold.variation,
      fontWeight: weightPalette.bold.amount,
      letterSpacing: "0.75px",
      lineHeight: "26px",
      minWidth: "auto",
      padding: "11px 34px 11px 34px",
      "&.Mui-selected": {
        "& span:first-child": {
          color: `var(${colorPalette.interactions.blueBerry.name})`,
        },
      },
      "&:hover": {
        background: `var(${colorPalette.interactions.lightBerry.name})`,
        borderRadius: "8px 8px 0 0",
      },
    },
    "& .swha-MuiAppBar-root": {
      display: "block",
      margin: "0 auto",
      maxWidth: "1440px",
      paddingLeft: "56px",
      paddingRight: "56px",
      "@media (max-width:1024px)": {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    "& .swha-MuiTabs-root": {
      display: "inline-flex",
      height: "48px",
      minHeight: "initial",
    },
  },
  tabMenuRootGCOM3695: {
    "& button.swha-MuiTab-root": {
      "&.Mui-selected": {
        "& span:first-child": {
          color: `var(${colorPalette.interactions.defaultCta.name})`,
        },
      },
    },
  },
  indicator: {
    "&&": {
      backgroundColor: `var(${colorPalette.interactions.blueBerry.name})`,
      height: "4px",
    },
  },
  indicatorGCOM3695: {
    "&&": {
      backgroundColor: `var(${colorPalette.interactions.defaultCta.name})`,
    },
  },
  rightSaveIndicator: {
    display: "inline-flex",
    float: "right" as const,
    paddingTop: "8px",
  },
};

export default tabMenuComponentStyles;
