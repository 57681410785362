import * as singleSpa from "single-spa";
import { useTranslation } from "react-i18next";
import styles from "./branding-header.styles";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { NewAppPaths } from "../../app.constants";
import { useContext } from "react";
import { AppContext } from "../../app.context";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

const useStyles = makeStyles(() => styles);

const BrandingHeader = () => {
  const classes = useStyles();
  const { isPreviewMode } = useContext(AppContext);
  const { t } = useTranslation();

  const handleClick = () => {
    singleSpa.navigateToUrl(NewAppPaths.TASKS_DASHBOARD);
  };

  return (
    <div className={classes.brandingRoot}>
      <div className={classes.brandingLeftContainer}>
        {!isPreviewMode && (
          <Button rootClassName="btn-tertiary" onClick={handleClick}>
            <ArrowBackIcon fillPath={colorPalette.interactions.defaultCta.hex} />
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling={"p3"} color={"white"} boldness={"semi"}>
                  {t("BACK TO MY TASKS")}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <span>{t("BACK TO MY TASKS")}</span>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </Button>
        )}
        <div className={classes.brandingSeparator} />
      </div>
      <BuySmartLogoOrangeStacked fillbottom={colorPalette.basic.white.hex} />
    </div>
  );
};

export default BrandingHeader;
