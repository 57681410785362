import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import * as singleSpa from "single-spa";
import { NewAppPaths } from "../../app.constants";
import { AppContext } from "../../app.context";
import SpinningSaveIndicator from "../spinning-save-indicator/spinning-save-indicator.component";
import { tabIndexToDataNewRoute } from "./tab-menu.lib";
import styles from "./tab-menu.styles";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";
import { useFeatureFlag } from "gx-npm-ui";

const useStyles = makeStyles(() => styles);
const TabMenu = () => {
  const isFFGCOM3606 = useFeatureFlag(GCOM_3606__fontUpdate);
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const { initId, initProdId, isScrolling, isPreviewMode, location, surveyHasRequestedDocs, setSelectedTab } =
    useContext(AppContext);
  const newTabIndexToData = tabIndexToDataNewRoute;

  useEffect(() => {
    newTabIndexToData.forEach((tab, index) => {
      if (location.pathname.endsWith(tab.pathName)) {
        setTabIndex(index);
        setSelectedTab(tab.name);
      }
    });
  }, [location, newTabIndexToData, setSelectedTab]);

  const handleTabChange = (newIndex: number) => {
    setTabIndex(newIndex);
    if (isPreviewMode) {
      const pathNameParts = window.location.pathname.split("/");
      pathNameParts.pop();
      const newPath = pathNameParts.join("/");
      singleSpa.navigateToUrl(`${newPath + newTabIndexToData[newIndex].pathName}`);
    } else {
      singleSpa.navigateToUrl(newTabIndexToData[newIndex].url(initId, initProdId));
    }
  };

  return (
    <section className={classes.tabMenuRoot}>
      <AppBar color="default" elevation={0} position="relative">
        <Tabs
          classes={{ indicator: classes.indicator }}
          indicatorColor="primary"
          onChange={(_event, index) => handleTabChange(index)}
          scrollButtons="auto"
          textColor="primary"
          value={tabIndex}
        >
          {newTabIndexToData.map((tab) => {
            const appPaths = NewAppPaths;
            const isTabExcluded = tab.pathName === appPaths.QUESTIONNAIRE_DOCUMENTS_TAB && !surveyHasRequestedDocs;
            return isTabExcluded ? null : (
              <Tab {...(isFFGCOM3606 && { classes: { wrapper: classes.tabClass } })} key={tab.name} label={tab.name} />
            );
          })}
        </Tabs>
        <div className={classNames(classes.rightSaveIndicator)}>
          {isScrolling && !isPreviewMode && <SpinningSaveIndicator />}
        </div>
      </AppBar>
    </section>
  );
};

export default TabMenu;
